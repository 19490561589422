import React from 'react';
import Layout from '../components/Layout';
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import Breadcrumbs from '../components/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { Link, graphql, useStaticQuery } from 'gatsby';

const SitemapPage = () => {
  const title = 'サイトマップ｜奈良のホームページ制作なら｜インヴォルブ';
  const description =
    '当サイトのサイトマップページです。各ページへのリンクをタイトル付きで一覧表示しています。';

  const breadcrumbList = [
    { name: 'ホーム', url: '/' },
    { name: 'サイトマップ', url: '/sitemap' },
  ];

  // GatsbyのGraphQLでページ一覧を取得
  const data = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `);

  // 除外するページ（詳細ページやシステムページ）
  const excludePaths = [
    '/dev-404-page/',
    '/sitemap/',
    '/404/',
    '/404.html/',
    '/demo/',
    '/thanks/',
  ];

  // ページタイトル設定（手動でリスト化 & 並び順を固定）
  const pageTitles = {
    '/': 'ホーム',
    '/about/': '会社概要',
    '/service/': 'サービス',
    '/service/new/': 'ホームページ制作',
    '/service/renewal/': 'ホームページリニューアル',
    '/service/update/': 'ホームページの更新代行',
    '/service/seo/': 'SEO対策',
    '/service/ads/': 'Web広告運用',
    '/service/ec/': 'ECサイト構築',
    '/service/cms/': 'CMSカスタマイズ',
    '/service/media/': '動画制作・写真撮影',
    '/service/consulting/': 'Webコンサルティング',
    '/service/server-management/': 'ドメイン・サーバー管理代行',
    '/service/writing/': '文章作成（ライティング）',
    '/service/troubleshooting/': '急なトラブル対応',
    '/service/audit/': 'サイト調査（サイト診断）',
    '/service/management/': 'サイト運営代行',
    '/service/analytics/': 'アクセス解析・改善提案',
    '/service/coding/': 'Webページコーディング開発代行',
    '/service/planning/': 'ウェブコンテンツ企画立案協力',
    '/news/': 'ニュース一覧',
    '/blog/': 'ブログ一覧',
    '/works/': '制作実績一覧',
    '/case-study/': '事例紹介一覧',
    '/faq/': 'よくある質問',
    '/privacy-policy/': 'プライバシーポリシー',
    '/contact/': 'お問い合わせ',
    '/sitemap/': 'サイトマップ',
  };

  // 特定のパターンに該当するページを除外
  const filteredPages = data.allSitePage.nodes
    .map((node) => node.path)
    .filter(
      (path) =>
        !excludePaths.includes(path) &&
        !path.match(/^\/news\/.+/) && // /news/ の詳細ページを除外
        !path.match(/^\/blog\/.+/) && // /blog/ の詳細ページを除外
        !path.match(/^\/works\/.+/) // /case-study/ の詳細ページを除外
    );

  // 取得したページの順番を `pageTitles` の順番に合わせる
  const sortedPages = Object.keys(pageTitles).filter((path) =>
    filteredPages.includes(path)
  );

  return (
    <Layout>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.involve-in.jp/sitemap/" />
        <link
          rel="icon"
          type="image/x-icon"
          href="/images/common/favicon.ico"
        />

        {/* Open Graph (OGP: Facebook, LinkedIn) */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.involve-in.jp/sitemap/" />
        <meta
          property="og:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />
      </Helmet>

      <div className="max-w-screen-lg w-full px-8 mx-auto py-24 bg-white bg-opacity-90">
        <h1 className="font-roboto text-6xl md:text-[6rem] font-bold text-center mb-12">
          サイトマップ
        </h1>

        <section className="container mx-auto py-16 px-6">
          <h2 className="text-3xl font-semibold mb-8 text-center">
            サイト内のページ
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {sortedPages.map((path) => (
              <Link
                key={path}
                to={path}
                className="block bg-white border border-gray-300 p-6 rounded-xl shadow-md hover:shadow-lg transition transform hover:scale-105"
              >
                <h3 className="text-2xl font-bold text-gray-900 mb-2">
                  {pageTitles[path]}
                </h3>
                <p className="text-2xl text-blue-500">{path}</p>
              </Link>
            ))}
          </div>
        </section>
      </div>

      <Breadcrumbs breadcrumbList={breadcrumbList} />
    </Layout>
  );
};

export default SitemapPage;
